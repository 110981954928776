<template>
  <div
    class="d-flex position-relative"
    @mouseenter="changeHover"
    @mouseleave="changeHover"
    @click="$bvModal.show(`delete-summary-item-modal-${item.id}`)"
    :id="`item-hover-${item.id}`"
  >
    <b-tooltip 
      v-if="item?.name?.length >= 38"
      :target="`item-hover-${item.id}`" 
      triggers="hover" 
      placement="bottom"
    >
      {{ item.name }}
    </b-tooltip>
    
    <div class="summary-item">
      <p class="summary-tag">
        {{ item?.name?.length >= 38 ? item.name.slice(0, 38)+'...' : item.name }}
      </p>
    </div>
    <transition>
      <div class="remove" v-if="hover">
        <Close class="icon" />
      </div>
    </transition>

    <ConfirmDelete
      :id="item.id"
      :name="item.name"
      :type="parseType"
      :confirmDelete="confirmDelete"
    />
  </div>
</template>

<script>
export default {
  name: 'SummaryItem',
  props: {
    item: Object
  },
  components: {
    ConfirmDelete: () => import('./ConfirmDelete.vue'),
    Close: () => import('@/assets/icons/close.svg')
  },
  data: () => ({
    hover: false
  }),
  computed: {
    parseType() {
      return {
        'antecedentes-oftalmologicos': 'a doença oftálmologica',
        'cirurgias-oftalmologicas-anteriores':
          'a cirurgia/procedimento oftalmológico',
        alergias: 'a alergia',
        'antecedentes-pessoais': 'o antecedente pessoal',
        'outros-medicamentos-em-uso': 'o medicamento em uso',
        'antecedentes-familiares': 'o antecedente familiar'
      }[this.item?.type]
    }
  },
  methods: {
    changeHover() {
      this.hover = !this.hover
    },
    async confirmDelete(deleteConfirmed) {
      if (deleteConfirmed)
        await this.deleteSummaryItem()
      else
        this.$bvModal.hide(`delete-summary-item-modal-${this.item.id}`)
    },
    async deleteSummaryItem() {
      const isLoading = this.$loading.show()
      try {
        await this.api.deleteSummaryItem(this.item.id)
        this.$emit('delete-summary-item', this.item.id)
        this.$toast.success('Item removido com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.summary-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  width: fit-content;
  border-radius: 30px;
  background-color: var(--light-orange-200);
  margin: 0 8px 8px 0;
  padding: 8px 16px;

  .summary-tag {
    font-weight: 600;
    font-size: 12px;
    color: var(--type-active);
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.remove {
  margin-top: -2% !important;
  margin-left: -20px;
  margin-right: 3px;
  border-radius: 50%;
  background-color: var(--states-error-soft-red);
  width: 17px;
  height: 17px;
  transition: 0.3s;

  .icon {
    margin-bottom: 12px;
    width: 15px;
    fill: var(--states-error);
    margin-left: 1px;
    margin-top: 1px;
  }
}
</style>
